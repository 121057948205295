import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentService } from '../shared/services/equipment.service';
import { SupportingDataService } from '../shared/services/supporting-data.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { TimezonesService } from '../shared/services/timezones.service';
import { LocalesService } from '../shared/services/locales.service';
import { MeterDetailsComponent } from '../details/meter/meter-details.component';
import { ControlSetDetailsComponent } from '../details/control-set/control-set-details.component';
import { MachineDetailsComponent } from '../details/machine/machine-details.component';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Equipment } from '../shared/model/equipment.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from '../delete/confirmation-dialog/delete-confirmation-dialog.component';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { BessDetailsComponent } from '../details/bess/bess-details.component';
import { GeneratorDetailsComponent } from '../details/generator/generator-details.component';
import { OrganizationsService } from '../shared/services/organizations.service';
import { EquipmentDetailsService } from '../shared/services/equipment-details.service';
import { EquipmentModelsService } from '../shared/services/equipment-models.service';
import { Formula, Parameter } from '../shared/model/formula.model';
import { NgForm } from '@angular/forms';
import { FormValidatorService } from '../shared/services/form-validator.service';
import { EquipmentGroupService } from '../shared/services/equipment-group.service';
import { GroupDetailsComponent } from '../details/group/group-details.component';
import { EquipmentGroup } from './../shared/model/equipment-group.model';
import { FlexibleAsset } from '../shared/model/flexible-asset.model';
import { FlexibleAssetsService } from '../shared/services/flexible-assets.service';
import { FlagService } from '../shared/services/flag.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  SUCCESS = 'Updated Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_UPDATED = 'Oops, There was a problem updating your equipment';
  REQUIRED = 'Required';
  orgId = '';
  loadingEquipment = false;
  loadingEquipmentGroup = false;
  loadingEquipmentList = true;
  sites = [];
  operators = [];
  isEquipment = true;
  isEquipmentGroup = false;
  equipmentOrgId: '';
  entityId: string;
  equipment: Equipment;
  equipmentGroup: EquipmentGroup;
  equipmentType = 'METER';
  equipmentTypes = [];
  formulas: Formula[];
  selectedFilters = [];
  appData: any = {};
  postingEquipment = false;
  locale: string;
  userLocale: 'en_US';

  subscriptions = [];

  saving: boolean = false;
  equipmentGroup_subtitle: string;

  multiLocaleConfig: LocaleBaseConfig = {
    locales: [new Locale()],
    supportedLocales: [new Locale()],
    defaultLocale: new Locale(),
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
    id: '',
  };

  readonly mode = 'edit';
  readonly APPPREFIX = 'eqp';
  private meterDetailsComponentView: MeterDetailsComponent;
  private controlSetDetailsComponentView: ControlSetDetailsComponent;
  private machineDetailsComponentView: MachineDetailsComponent;
  private bessDetailsComponentView: BessDetailsComponent;
  private generatorDetailsComponentView: GeneratorDetailsComponent;
  private groupDetailsComponentView: GroupDetailsComponent;

  @ViewChild(MeterDetailsComponent)
  set meterComponent(meterDetailsComponent: MeterDetailsComponent) {
    this.meterDetailsComponentView = meterDetailsComponent;
  }

  @ViewChild(ControlSetDetailsComponent)
  set ControlSetComponent(controlSetDetailsComponent: ControlSetDetailsComponent) {
    this.controlSetDetailsComponentView = controlSetDetailsComponent;
  }

  @ViewChild(MachineDetailsComponent)
  set MachineComponent(machineDetailsComponent: MachineDetailsComponent) {
    this.machineDetailsComponentView = machineDetailsComponent;
  }

  @ViewChild(BessDetailsComponent)
  set BessComponent(bessDetailsComponent: BessDetailsComponent) {
    this.bessDetailsComponentView = bessDetailsComponent;
  }

  @ViewChild(GeneratorDetailsComponent)
  set GeneratorComponent(generatorDetailsComponent: GeneratorDetailsComponent) {
    this.generatorDetailsComponentView = generatorDetailsComponent;
  }

  @ViewChild(GroupDetailsComponent)
  set GroupComponent(groupDetailsComponent: GroupDetailsComponent) {
    this.groupDetailsComponentView = groupDetailsComponent;
  }

  @ViewChild('edit', { static: true }) editForm: NgForm;

  constructor(
    private timezonesService: TimezonesService,
    private localesService: LocalesService,
    private ngxCookieService: NgxCookieService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private equipmentService: EquipmentService,
    private supportingDataService: SupportingDataService,
    private orgSelectorService: ContextSelectorService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private organizationsService: OrganizationsService,
    private equipmentDetailsService: EquipmentDetailsService,
    private equipmentModelService: EquipmentModelsService,
    private formValidatorService: FormValidatorService,
    private equipmentGroupService: EquipmentGroupService,
    private flexibleAssetService: FlexibleAssetsService,
    private flagService: FlagService
  ) {
    this.equipmentType = 'METER';
    this.orgId = this.ngxCookieService.getCookie('preferred-org-id') || '';
    this.appData.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';

    const translateSub = this.translateService
      .get('equipment.notification.updated_successfully')
      .subscribe((result: string) => {
        this.SUCCESS = result;
        this.BAD_REQUEST = this.translateService.instant('equipment.notification.bad_request');
        this.NOT_UPDATED = this.translateService.instant('equipment.notification.not_updated');
        this.REQUIRED = this.translateService.instant('equipment.validation.required');
      });

    const routeSub = this.route.params.subscribe(async params => {
      if (params.id) {
        const { id } = params;
        this.entityId = id;
        if (this.route.queryParams['value']['type'] && this.route.queryParams['value']['type'] === 'EQUIPMENT_GROUP') {
          this.equipmentGroup = null;
          this.loadingEquipmentGroup = true;
          await this.equipmentGroupService.getEquipmentGroupById(id);
          this.isEquipment = false;
          this.loadingEquipmentGroup = false;
          this.isEquipmentGroup = true;
          this.equipmentType = 'EQUIPMENT_GROUP';
          this.equipmentGroup_subtitle = this.translateService.instant('equipmentGroup.edit.subtitle');
        } else {
          this.equipment = null;
          this.loadingEquipment = true;
          this.isEquipmentGroup = false;
          this.equipmentDetailsService.selectedEquipment.next(id);
          this.isEquipment = true;
          await this.equipmentDetailsService.getEquipmentDetails(id);
          this.loadingEquipment = false;
        }
      }
    });

    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }

    const currentContextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      if (orgs && orgs.length > 0) {
        const org = orgs[0];
        this.appData.loadingSites = true;
        this.appData.sites = [];
        this.orgId = org.id;
        this.loadDynamicLists(org.id);
      }
    });

    const formulasSub = equipmentModelService.formulas$.subscribe(formulas => {
      this.formulas = formulas;
    });

    const equipmentsTypesSub = this.equipmentService.equipmentTypesDropDown$.subscribe(equipmentTypes => {
      if (equipmentTypes.length > 0) {
        this.equipmentTypes = equipmentTypes;
        this.appData.equipmentTypes = equipmentTypes;
      }
    });

    const sitesSub = this.equipmentService.sites$.subscribe(sites => {
      this.appData.sites = sites;
      this.appData.loadingSites = false;
    });

    const siteMetersSub = this.equipmentService.siteMeters$.subscribe(siteMeters => {
      this.appData.siteMeters = siteMeters;
      this.appData.loadingSiteMeters = false;
    });

    const devicesSub = this.equipmentService.devices$.subscribe(devices => {
      this.appData.devices = devices;
      this.appData.loadingDevices = false;
    });

    const siteMachinesSub = this.equipmentService.siteMachines$.subscribe(siteMachines => {
      this.appData.siteMachines = siteMachines;
      if (siteMachines !== null) {
        this.appData.loadingSiteMachines = false;
      }
    });

    const equipmentSub = this.equipmentDetailsService.equipment$.subscribe(equipment => {
      if (equipment) {
        const { id, equipmentType } = equipment;
        this.equipmentDetailsService.selectedEquipment.next(id);
        this.equipment = equipment;
        this.appData.equipment = equipment;
        this.equipmentType = equipmentType;
        //this.organizationsService.getOrgs(organization.id);
        if (this.equipment && this.equipment.organization) {
          this.organizationsService.getOrgs(this.equipment.organization.id);
        } else {
          // fallback in case we don't have org for whatever reason.
          this.organizationsService.getOrgs();
        }
        this.formValidatorService.triggerValidation(this.editForm.form);
      }
    });

    const equipmentGroupSub = this.equipmentGroupService.fullEquipmentGroup$.subscribe(equipmentGroup => {
      if (equipmentGroup) {
        this.equipmentGroup = equipmentGroup;
        this.appData.equipmentgroup = equipmentGroup;
        this.formValidatorService.triggerValidation(this.editForm.form);
      }
    });

    const operatorsSub = this.supportingDataService.operators$.subscribe(operators => {
      if (operators.length > 0) {
        this.operators = operators;
      }
    });

    const localesSub = this.localesService.locales$.subscribe(locales => {
      this.multiLocaleConfig.locales = locales;
      const locale = this.localesService.checkForLocale(this.appData.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;
      this.multiLocaleConfig.supportedLocales = [locale];
    });

    const fullEquipmentListSub = this.equipmentService.flattenedOrgEquipment$.subscribe(equipmentList => {
      this.loadingEquipmentList = !(equipmentList && equipmentList.length);
    });

    this.subscriptions.push(
      translateSub,
      routeSub,
      equipmentGroupSub,
      localesSub,
      operatorsSub,
      equipmentSub,
      siteMachinesSub,
      devicesSub,
      siteMetersSub,
      sitesSub,
      equipmentsTypesSub,
      formulasSub,
      currentContextSub,
      fullEquipmentListSub
    );

    this.appData.loadingDevices = true;
    this.appData.loadingSiteMachines = true;
    this.equipmentService.setEquipmentTypes();
    this.supportingDataService.setOperators();
    this.timezonesService.setTimezones();
    this.equipmentService.setDevicesAndCommands();
    this.localesService.setLocales();
    this.flagService.setFlags();
  }

  ngOnInit() {}

  handleCancel() {
    setTimeout(() => {
      this.router.navigate([`details/${this.entityId}/view`], { queryParamsHandling: 'preserve' });
    }, 2000);
  }

  async handleSubmit() {
    if (!this.editForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      this.saving = true;

      if (this.isEquipment) {
        try {
          let equipmentModel = this.getModelForEquipmentType();
          equipmentModel = JSON.parse(JSON.stringify(equipmentModel));
          this.postingEquipment = true;
          const response = await this.equipmentService.updateEquipment(equipmentModel);
          this.postingEquipment = false;
          this.equipmentService.ForceReloadNoCached();
          this.handleResponse(response);
        } catch (e) {
          if (e && e.error === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            this.messageService.setError(this.NOT_UPDATED);
          }
          this.postingEquipment = false;
        }
      }

      if (this.isEquipmentGroup) {
        try {
          let equipmentGroupModel = this.getModelForEquipmentType();
          this.postingEquipment = true;

          const response = await this.equipmentGroupService.updateEquipmentGroup(equipmentGroupModel);

          const prevParentId = equipmentGroupModel.topology[0].id;
          const newParentId = equipmentGroupModel.parentId;

          if (prevParentId !== newParentId) {
            await this.equipmentGroupService.deleteRelationships(prevParentId, equipmentGroupModel.id);
            await this.equipmentGroupService.createRelationships(newParentId, equipmentGroupModel.id);
          }
          let initialState = this.equipmentGroupService.getInitailState();
          initialState = initialState ? initialState : [];
          let selectedEquipment = this.equipmentGroupService.getEquipmentsSelected();
          selectedEquipment = selectedEquipment ? selectedEquipment : [];
          const initiatToSelected = initialState.filter(currentItem => !selectedEquipment.includes(currentItem));
          const selectedToInitial = selectedEquipment.filter(currentItem => !initialState.includes(currentItem));
          if (initiatToSelected.length > 0 || selectedToInitial.length > 0) {
            const removedItems = this.equipmentGroupService.getRemovedItems();
            const addedItems = this.equipmentGroupService.getAddedItems();

            const siteId = this.equipmentService.getSiteIdFromEquipment(newParentId);
            const assets = await this.flexibleAssetService.getFlexibleAssets(siteId);
            if (removedItems) {
              for (const element of removedItems) {
                if (initialState.includes(element)) {
                  await this.equipmentGroupService.deleteRelationships(equipmentGroupModel.id, element.id);
                  await this.equipmentGroupService.createRelationships(newParentId, element.id);
                  // Only do this if new parent is not an equipment group
                  if(element.equipmentType === 'METER' && !this.equipmentService.isEquipmentGroup(newParentId)) {
                    const fullMeter = await this.equipmentService.getEquipment(element.id);
                    for (const asset of assets) {
                      const updatedAsset: FlexibleAsset = this.flexibleAssetService.removeMeterFromEquipmentGroup(fullMeter, asset, siteId);
                      await this.flexibleAssetService.updateFlexibleAsset(updatedAsset);
                    }
                  }
                }
              }
            }

            if (addedItems) {
              for (const element of addedItems) {
                if (!initialState.includes(element)) {
                  await this.equipmentGroupService.deleteRelationships(element.parentId, element.id);
                  await this.equipmentGroupService.createRelationships(equipmentGroupModel.id, element.id);
                  if(element.equipmentType === 'METER') {
                    const fullMeter = await this.equipmentService.getEquipment(element.id);
                    for (const asset of assets) {
                      const updatedAsset: FlexibleAsset = this.flexibleAssetService.addMeterFromEquipmentGroup(fullMeter, asset, siteId);
                      await this.flexibleAssetService.updateFlexibleAsset(updatedAsset);
                    }
                  }
                }
              }
            }
          }
          this.postingEquipment = false;
          this.equipmentService.ForceReloadNoCached();
          this.handleResponse(response);
          this.equipmentService.getOrgEquipment(this.orgId);
          this.equipmentGroupService.getOrgEquipmentGroups(this.orgId);
        } catch (e) {
          if (e && e.error === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            this.messageService.setError(this.NOT_UPDATED);
          }
          this.postingEquipment = false;
        }
      }
    }
  }

  getEquipmentTypeDisplayLabel() {
    if (this.equipmentTypes && this.equipment.equipmentType) {
      return this.equipmentTypes.filter((e: any) => e.name === this.equipment.equipmentType)[0].displayLabel;
    }
  }

  private getModelForEquipmentType() {
    let equipment: any = {};
    switch (this.equipmentType) {
      case 'CONTROL_SET':
        equipment = {
          ...this.controlSetDetailsComponentView.controlSet,
        };

        if (equipment.address === '') {
          delete equipment.address;
        }

        if (equipment.provider === '') {
          delete equipment.provider;
        }
        break;
      case 'MACHINERY':
        equipment = {
          ...this.machineDetailsComponentView.machine,
        };
        break;
      case 'BESS':
        equipment = {
          ...this.bessDetailsComponentView.bess,
        };

        if (equipment.derosBessId === '') {
          delete equipment.derosBessId;
        }
        break;
      case 'GENERATOR':
        equipment = {
          ...this.generatorDetailsComponentView.generator,
        };

        break;
      case 'EQUIPMENT_GROUP':
        equipment = {
          ...this.groupDetailsComponentView.group,
        };

        break;
      default:
        equipment = {
          ...this.meterDetailsComponentView.meter,
        };

        if (equipment.operator === '-1') {
          delete equipment.operator;
        }

        if (equipment.marketMeterId === '') {
          delete equipment.marketMeterId;
        }

        for (const point of equipment.points) {
          if (point.formulaId) {
            const formula = this.formulas.find((formulaTemp: Formula) => formulaTemp.id === point.formulaId);
            Object.keys(point.formulaParameters).forEach((param: any) => {
              point.formulaParameters[param] = this.parseFormulaParameter(
                point.formulaParameters[param],
                formula.parameters[param],
              );
            });
          } else {
            delete point.formulaId;
            delete point.formulaParameters;
          }

          if (typeof point.dataProvider === 'object') {
            point.dataProvider = point.dataProvider.id;
          }

          if (typeof point.deliveredChannelId === 'number') {
            point.deliveredChannelId = point.deliveredChannelId.toString();
          }

          if (typeof point.channelId === 'number') {
            point.channelId = point.channelId.toString();
          }

          if (typeof point.reportingInterval === 'number') {
            point.reportingInterval = point.reportingInterval.toString();
          }
        }
    }
    equipment.equipmentType = this.equipmentType;
    return equipment;
  }

  parseFormulaParameter(parameter: any, formulaParameter: Parameter) {
    if (formulaParameter.type === 'number') {
      return parseFloat(parameter);
    }
    if (formulaParameter.type === 'boolean') {
      return parameter === 'true';
    }
  }

  openDeleteConfirmation() {
    const dialogConfig = new MatDialogConfig();
    const groupLabel =
      this.equipmentGroup && this.equipmentGroup.coreSpaceType
        ? this.equipmentGroup.coreSpaceType.toLowerCase()
        : 'equipment';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.closeOnNavigation = true;
    dialogConfig.data = {
      id: this.isEquipment ? this.equipment.id : this.equipmentGroup.id,
      displayLabel: this.isEquipment ? this.equipment.displayLabel : this.equipmentGroup.displayLabel,
      equipmentType: this.isEquipment ? this.equipment.equipmentType.toLowerCase().replace('_', '-') : groupLabel,
      isGroup: this.isEquipmentGroup,
    };
    this.dialog.open(DeleteConfirmationComponent, dialogConfig);
  }

  getDisplayLabel() {
    if (this.equipment?.displayLabels && this.appData.userLocale) {
      return this.equipment.displayLabels[this.appData.userLocale];
    }
  }

  getGroupDisplayLabel() {
    if (this.equipmentGroup) {
      return this.equipmentGroup.displayLabel;
    }
  }

  private handleResponse(response: any) {
    if (response.code === 207) {
      const equipmentId = response.root ? response.root.id : response.data.id;
      // partial-success
      if (response.errors) {
        let errorString = '';
        for (const error of response.errors) {
          if (error.uncleError) {
            errorString += error.displayLabel + ': ' + error.description;
          } else {
            errorString += error.message ? ' ' + error.message + '\n' : `${this.NOT_UPDATED} : ${error.atlasMessage}`;
          }
        }
        this.messageService.setError(errorString);
      } else {
        this.messageService.setError(this.BAD_REQUEST);
      }

      setTimeout(() => {
        this.router.navigate([`details/${equipmentId}/edit`], {});
      }, 2000);
    } else if (response.code === 200) {
      const equipmentId = response.data.id;
      this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: equipmentId });
      this.messageService.setSuccess(this.SUCCESS);

      if (this.isEquipment) {
        setTimeout(() => {
          this.equipmentService.refetchEquipment();
          this.router.navigate([`details/${equipmentId}/view`], {});
        }, 2000);
      }

      if (this.isEquipmentGroup) {
        setTimeout(() => {
          this.router.navigate([`details/${equipmentId}/view`], { queryParams: { type: 'EQUIPMENT_GROUP' } });
        }, 2000);
      }
    } else if (response.id) {
      this.messageService.setSuccess(this.SUCCESS);

      if (this.isEquipment) {
        setTimeout(() => {
          this.equipmentService.refetchEquipment();
          this.router.navigate([`details/${response.id}/view`], {});
        }, 2000);
      }

      if (this.isEquipmentGroup) {
        setTimeout(() => {
          this.router.navigate([`details/${response.id}/view?type=EQUIPMENT_GROUP`], {});
        }, 2000);
      }
    } else {
      this.messageService.setError(this.NOT_UPDATED);
    }
  }

  private async loadDynamicLists(orgId: string) {
    if (orgId) {
      this.appData.loadingSiteMeters = true;
      this.equipmentService.setOrgEquipment(orgId);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
