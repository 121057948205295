<div
    id="ControlElementsForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
    ngModelGroup="controlsForm_{{ index }}"
>
    <mat-expansion-panel
        (opened)="expandPanel()"
        (closed)="panelOpenedState = false"
        togglePosition="before"
        class="expansion-panel panel_{{ index }}"
    >
        <mat-expansion-panel-header class="panel-override" collapsedHeight="74px" expandedHeight="48px" data-automation="controlElementDetails">
            <mat-panel-title class="point-title">
                <div data-automation="displayLabel">
                    <ng-container>
                        {{ displayLabel }}
                    </ng-container>
                </div>
                <div>
                    <i *ngIf="isCreateMode || isEditMode" (click)="addControl($event)" id="add_new_panel_{{ index }}"
                        ><img class="edit-icon" src="../../../assets/favicons/enelx/plusButton.svg" alt=""
                    /></i>
                    <i *ngIf="isViewMode" (click)="handleEdit($event)"
                        ><img
                            class="edit-icon"
                            id="edit_panel_{{ index }}"
                            src="../../../assets/favicons/enelx/edit-new.svg"
                            alt=""
                    /></i>
                    <i *ngIf="(isCreateMode || isEditMode) && canDelete" (click)="deleteControl($event, index)"
                        ><img
                            class="edit-icon"
                            data-index="{{ index }}"
                            id="delete_panel_{{ index }}"
                            src="../../../assets/favicons/enelx/delete.svg"
                            alt=""
                    /></i>
                </div>
            </mat-panel-title>

            <mat-panel-description class="point-subtitle">
                {{ panelOpenedState ? '' : control.controlElementName }}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="panel-override">
            <!--- CREATE / EDIT --->
            <div *ngIf="isCreateMode || isEditMode">
                <div class="form-entry">
                    <multi-locale-input
                        name="displayLabels"
                        [inputLabel]="nameLabel"
                        [locales]="localeConfig.supportedLocales"
                        [(ngModel)]="control.displayLabels"
                        [displayLabelKey]="localeConfig.displayLabelKey"
                        [localeKey]="localeConfig.localeKey"
                        [defaultLocale]="localeConfig.defaultLocale"
                        [placeholderText]="namePlaceholder"
                        [required]="true"
                        [id]="getDisplayLabelsId()"
                    >
                    </multi-locale-input>
                </div>

                <div class="form-entry" *ngIf="isEditMode">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'controlElement.create.id' | translate }}</mat-label>
                        <input
                                matInput
                                id="id_{{ index }}"
                                #id="ngModel"
                                type="text"
                                name="id_{{ index }}"
                                placeholder="{{ 'controlElement.create.placeholder.id' | translate }}"
                                [(ngModel)]="control.id"
                                data-automation="controlId"
                                data-url-persist
                                disabled
                        />
                    </mat-form-field>
                </div>
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'controlElement.create.relayName' | translate }}</mat-label>
                        <input
                            matInput
                            id="relayName_{{ index }}"
                            #relayName="ngModel"
                            type="text"
                            [ngClass]="{ failure: relayName.invalid && (relayName.dirty || relayName.touched) }"
                            name="relayName_{{ index }}"
                            placeholder="{{ 'controlElement.create.placeholder.relayName' | translate }}"
                            maxlength="80"
                            [(ngModel)]="control.controlElementName"
                            data-automation="relayName"
                            data-url-persist
                            required
                            pattern="^[a-zA-Z0-9\-_\\s]+$"
                        />
                        <mat-error *ngIf="relayName.invalid">
                            <mat-error *ngIf="relayName.errors.required">
                                <span data-automation="alertRequired">{{
                                    'equipment.validation.required' | translate
                                }}</span>
                            </mat-error>
                            <mat-error *ngIf="relayName.errors.pattern">
                                <span data-automation="patternError">{{ 'equipment.validation.pattern' | translate }}</span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'controlElement.create.controlType' | translate }}</mat-label>
                        <mat-select
                            id="controlType_{{ index }}"
                            name="controlType_{{ index }}"
                            #controlType="ngModel"
                            [class.loading-field]="_data.loadingControlTypes"
                            [ngClass]="{
                                failure: controlType.invalid && (controlType.dirty || controlType.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="control.controlType"
                            data-automation="controlType"
                            required
                            placeholder="{{ 'controlElement.create.placeholder.controlType' | translate }}"
                        >
                            <mat-option
                                *ngFor="let controlType of controlTypes"
                                [value]="controlType.name"
                                id="controlType_{{ controlType.displayLabel }}"
                                >{{ controlType.displayLabel }}</mat-option
                            >
                        </mat-select>
                        <mat-error *ngIf="controlType.invalid">
                            <mat-error *ngIf="controlType.errors.pattern">
                                {{ 'equipment.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="_data.loadingControlTypes" align="end">{{
                            'equipment.loading' | translate
                        }}</mat-hint>
                        <mat-spinner *ngIf="_data.loadingControlTypes" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry">
                    <multi-locale-input
                        name="descriptions"
                        [inputLabel]="descriptionLabel"
                        [locales]="localeConfig.supportedLocales"
                        [(ngModel)]="control.descriptions"
                        [displayLabelKey]="localeConfig.displayLabelKey"
                        [localeKey]="localeConfig.localeKey"
                        [defaultLocale]="localeConfig.defaultLocale"
                        [placeholderText]="descriptionPlaceholder"
                        [required]="false"
                        [id]="getDescriptionsId()"
                    >
                    </multi-locale-input>
                </div>

                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'controlElement.create.availableCommands' | translate }}</mat-label>
                        <mat-select
                            id="availableCommands_{{ index }}"
                            name="availableCommands_{{ index }}"
                            #availableCommands="ngModel"
                            [class.loading-field]="_data.loadingDevices"
                            [ngClass]="{
                                failure:
                                    availableCommands.invalid && (availableCommands.dirty || availableCommands.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="control.availableCommands"
                            [disabled]="!deviceCommands"
                            multiple
                            required
                            placeholder="{{ 'controlElement.create.placeholder.availableCommands' | translate }}"
                        >
                            <mat-option
                                *ngFor="let command of deviceCommands"
                                [value]="command.name"
                                id="availableCommands_{{ command.name }}"
                                >{{ command.displayLabel }}</mat-option
                            >
                        </mat-select>
                        <mat-error *ngIf="availableCommands.invalid">
                            <mat-error *ngIf="availableCommands.errors.pattern">
                                {{ 'equipment.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="_data.loadingDevices" align="end">{{
                            'equipment.loading' | translate
                        }}</mat-hint>
                        <mat-spinner *ngIf="_data.loadingDevices" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry" *ngIf="control.availableCommands">
                    <mat-list>
                        <mat-list-item
                            class="list-item"
                            *ngFor="let selectedCommand of this._control.availableCommands; let commandIndex = index"
                        >
                            <i
                                (click)="removeSelectedCommand(selectedCommand)"
                                id="removeSelectedCommand{{ index }}_{{ commandIndex }}"
                                ><img class="edit-icon" src="../../../assets/favicons/enelx/minus-button.svg" alt=""
                            /></i>
                            <span class="label">
                                {{ getAvailableCommandsDisplayLabel(selectedCommand) }}
                            </span>
                        </mat-list-item>
                    </mat-list>
                    <ng-container *ngIf="control.availableCommands.length === 0">
                        <mat-label class="info-entry">{{
                            'controlElement.create.placeholder.noCommands' | translate
                        }}</mat-label>
                    </ng-container>
                </div>

                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'controlElement.create.controls' | translate }}</mat-label>
                        <mat-select
                            id="controls_{{ index }}"
                            name="controls_{{ index }}"
                            #controls="ngModel"
                            [class.loading-field]="_data.loadingSiteMeters"
                            [ngClass]="{
                                failure: controls.invalid && (controls.dirty || controls.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="control.controls"
                            data-automation="controls"
                            [disabled]="!siteMeters"
                            multiple
                            required
                            placeholder="{{ 'controlElement.create.placeholder.controls' | translate }}"
                        >
                            <mat-option
                                *ngFor="let meter of siteMeters"
                                [value]="meter.id"
                                id="meters_{{ meter.id }}"
                                >{{ meter.displayLabel }}</mat-option
                            >
                        </mat-select>
                        <mat-error *ngIf="controls.invalid">
                            <mat-error *ngIf="controls.errors.pattern">
                                {{ 'equipment.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="_data.loadingSiteMeters" align="end">{{
                            'equipment.loading' | translate
                        }}</mat-hint>
                        <mat-spinner *ngIf="_data.loadingSiteMeters" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>

                <div class="form-entry" *ngIf="control.controls">
                    <mat-list>
                        <mat-list-item
                            class="list-item"
                            *ngFor="let selectedMeterId of this._control.controls; let meterIndex = index"
                        >
                            <ng-container *ngIf="getMeterDisplayLabel(selectedMeterId)">
                                <i
                                    (click)="removeSelectedMeter(selectedMeterId)"
                                    id="removeSelectedMeter_{{ index }}_{{ meterIndex }}"
                                    ><img
                                        class="edit-icon"
                                        src="../../../assets/favicons/enelx/minus-button.svg"
                                        alt=""
                                /></i>
                                <span class="label">
                                    {{ getMeterDisplayLabel(selectedMeterId) }}
                                </span>
                            </ng-container>
                        </mat-list-item>
                    </mat-list>
                    <ng-container *ngIf="control.controls.length === 0">
                        <mat-label class="info-entry">{{
                            'controlElement.create.placeholder.noControls' | translate
                        }}</mat-label>
                    </ng-container>
                </div>
            </div>

            <!--- VIEW --->
            <ng-container *ngIf="isViewMode">
                <div class="form-entry">
                    <mat-label class="info-title">{{ 'controlElement.create.id' | translate }}:</mat-label>
                    <mat-label class="reg-name-value info-value" data-automation="elementID_{{ index }}">
                        <span
                        matTooltip="{{ control.id }}" matTooltipPosition="above" data-automation="viewControlId">
                            {{ control.id }}
                        </span>
                    </mat-label>
                </div>

                <div class="form-entry">
                    <mat-label class="info-title">{{ 'controlElement.create.relayName' | translate }}:</mat-label>
                    <mat-label class="reg-name-value info-value" data-automation="relayName_{{ index }}">
                        {{ control.controlElementName }}
                    </mat-label>
                </div>

                <div class="form-entry">
                    <mat-label class="info-title">{{ 'equipment.create.equipment_type' | translate }}:</mat-label>
                    <mat-label class="reg-name-value info-entry" data-automation="relayName">
                        {{ getEquipmentTypeDisplayLabel() }}
                    </mat-label>
                </div>

                <div class="form-entry">
                    <mat-label class="info-title">{{ 'controlElement.create.controlType' | translate }}:</mat-label>
                    <mat-label class="info-entry">
                        {{ getControlType() }}
                    </mat-label>
                </div>

                <div class="form-entry">
                    <mat-label class="info-title">{{ 'sharedFields.create.description' | translate }}:</mat-label>
                    <mat-label *ngIf="control.description" class="info-entry">
                        {{ _control.description }}
                    </mat-label>
                    <mat-label *ngIf="!control.description" class="info-placeHolder">
                        {{ 'sharedFields.view.placeholder.description' | translate }}
                    </mat-label>
                </div>

                <div class="form-entry">
                    <mat-label class="info-title">{{ 'controlElement.create.controls' | translate }}:</mat-label>
                    <div>
                        <ng-container *ngFor="let meter of this._control.controls">
                            <mat-label class="control-value info-entry">
                                {{ getMeterDisplayLabel(meter) }}
                            </mat-label>
                        </ng-container>
                    </div>
                </div>

                <div class="form-entry">
                    <mat-label class="info-title"
                        >{{ 'controlElement.create.availableCommands' | translate }}:</mat-label
                    >
                    <div>
                        <ng-container *ngFor="let commandId of this._control.availableCommands">
                            <mat-label class="control-value info-entry">
                                {{ getAvailableCommandsDisplayLabel(commandId) }}
                            </mat-label>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-expansion-panel>
</div>
