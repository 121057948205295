import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class FlagService {
  flags$: BehaviorSubject<any>;

  constructor(private apiService: ApiService) {
    this.flags$ = new BehaviorSubject<any>({});
  }

  async setFlags() {
    const flags = await this.apiService.get('flags');
    this.flags$.next(flags);
  }

  getFlag(flag: string) {
    return (this.flags$.getValue()[flag] || false);
  }
}
