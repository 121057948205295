<ng-container *ngIf="areChannelsReady">
<div
    id="measuredPointsForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
    ngModelGroup="measuredPointsForm_{{ index }}"
    data-automation="measuredPointsForm"
>
    <mat-expansion-panel
        (opened)="expandPanel()"
        (closed)="panelOpenedState = false"
        togglePosition="before"
        class="expansion-panel panel_{{ index }}"
    >
        <mat-expansion-panel-header class="panel-override" collapsedHeight="74px" expandedHeight="48px">
            <mat-panel-title class="point-title" data-automation="point-title">
                <div data-automation="displayLabel">
                    <ng-container>
                        {{ displayLabel }}
                    </ng-container>
                </div>
                <div>
                    <i *ngIf="isCreateMode || isEditMode" (click)="addPoint($event)" id="add_new_panel_{{ index }}">
                        <img class="edit-icon" src="../../../assets/favicons/enelx/plusButton.svg" alt="" />
                    </i>
                    <i *ngIf="isViewMode" (click)="handleEdit($event)">
                        <img
                            class="edit-icon"
                            id="edit_panel_{{ index }}"
                            src="../../../assets/favicons/enelx/edit-new.svg"
                            alt=""
                        />
                    </i>
                    <i *ngIf="(isCreateMode || isEditMode) && canDelete" (click)="deletePoint($event, index)">
                        <img
                            class="edit-icon"
                            data-index="{{ index }}"
                            id="delete_panel_{{ index }}"
                            src="../../../assets/favicons/enelx/delete.svg"
                            alt=""
                        />
                    </i>
                </div>
            </mat-panel-title>

            <mat-panel-description class="point-subtitle">
                {{ panelOpenedState ? '' : pointSourceId }}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="panel-override">
            <div class="form-entry" *ngIf="isViewMode || isEditMode">
                <mat-form-field *ngIf="isEditMode" appearance="outline" floatLabel="always" data-automation="pointIdField">
                    <mat-label>{{ 'measuredPoint.create.uuid' | translate }}</mat-label>
                    <input
                            matInput
                            id="id_{{ index }}"
                            #id="ngModel"
                            type="text"
                            name="id_{{ index }}"
                            [(ngModel)]="point.id"
                            data-automation="pointIdValue"
                            data-url-persist
                            disabled
                    />
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'measuredPoint.create.uuid' | translate }}:</mat-label>
                    <mat-label class="info-value">
                        <span
                            matTooltip="{{ point.id }}" matTooltipPosition="above" data-automation="pointIdValue">
                            {{ point.id }}
                        </span>
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.measuring' | translate }}</mat-label>
                    <mat-select
                        id="measuring_{{ index }}"
                        name="measuring_{{ index }}"
                        #measuring="ngModel"
                        class="create-select"
                        [ngClass]="{ failure: measuring.invalid && (measuring.dirty || measuring.touched) }"
                        [(ngModel)]="point.deliveredChannelId"
                        data-automation="measuringSelector"
                        (selectionChange)="onMeasuringChange($event)"
                        [class.loading-field]="loadingChannels"
                        appSelectValid
                        [compareWith]="channelCompare"
                        placeholder="{{ 'measuredPoint.create.placeholder.measuring' | translate }}"
                        required
                    >
                        <mat-option
                            *ngFor="let channel of channels"
                            [value]="channel.id"
                            id="measuring_{{ channel.id }}"
                            [disabled]="checkExistingPointChannels(channel.id)"
                            >{{ channel.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="measuring.invalid && (measuring.dirty || measuring.touched)">
                        <mat-error *ngIf="measuring.errors.required || measuring.errors.selectValid">
                            <span data-automation="alertRequired">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingChannels" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="loadingChannels" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'measuredPoint.create.measuring' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="measuring">
                        {{ pointChannel }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.type' | translate }}</mat-label>
                    <mat-select
                        id="pointType_{{ index }}"
                        name="pointType_{{ index }}"
                        #pointType="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{ failure: pointType.invalid && (pointType.dirty || pointType.touched) }"
                        [(ngModel)]="point.type"
                        data-automation="pointTypeSelector"
                        [class.loading-field]="loadingPointTypes"
                        appSelectValid
                        [compareWith]="typeCompare"
                        placeholder="{{ 'measuredPoint.create.placeholder.type' | translate }}"
                    >
                        <mat-option
                            *ngFor="let type of pointTypes"
                            [value]="type.id"
                            [disabled]="type.status === 'INACTIVE'"
                            id="type_{{ type.id }}"
                        >
                            {{ type.displayLabel }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="pointType.invalid && (pointType.dirty || pointType.touched)">
                        <mat-error *ngIf="pointType.errors.required || pointType.errors.selectValid">
                            <span data-automation="alertRequired">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingPointTypes" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="loadingPointTypes" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'measuredPoint.create.type' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="pointType">
                        {{ pointType }}
                    </mat-label>
                </ng-container>
            </div>
            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="localeConfig.supportedLocales"
                    [(ngModel)]="point.displayLabels"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (change)="onChangeInput($event)"
                    [displayLabelKey]="localeConfig.displayLabelKey"
                    [localeKey]="localeConfig.localeKey"
                    [defaultLocale]="localeConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [id]="getDisplayLabelsId()"
                    [maxLength]="500"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.reporting_interval' | translate }}</mat-label>
                    <mat-select
                        id="reportingInterval_{{ index }}"
                        name="reportingInterval_{{ index }}"
                        #reportingInterval="ngModel"
                        class="create-select"
                        [ngClass]="{
                            failure: reportingInterval.invalid && (reportingInterval.dirty || reportingInterval.touched)
                        }"
                        [(ngModel)]="point.reportingInterval"
                        data-automation="reportingIntervalSelector"
                        appSelectValid
                        [class.loading-field]="loadingReportingIntervals"
                        required
                        [compareWith]="reportingIntervalCompare"
                        placeholder="{{ 'measuredPoint.create.placeholder.reporting_interval' | translate }}"
                    >
                        <mat-option
                            *ngFor="let interval of reportingIntervals"
                            [value]="interval.durationInMilliseconds"
                            id="interval_{{ interval.id }}"
                        >
                            {{ interval.displayLabel }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="reportingInterval.invalid && (reportingInterval.dirty || reportingInterval.touched)">
                        <mat-error *ngIf="reportingInterval.errors.required || reportingInterval.errors.selectValid">
                            <span data-automation="alertRequired">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingReportingIntervals" align="end">{{
                        'equipment.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="loadingReportingIntervals" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">
                        {{ 'measuredPoint.create.reporting_interval' | translate }}:
                    </mat-label>
                    <mat-label class="timezone-value info-value" data-automation="reportingInterval">
                        {{ getPointReportingInterval() }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.data_provider' | translate }}</mat-label>
                    <mat-select
                        id="dataProvider_{{ index }}"
                        name="dataProvider_{{ index }}"
                        #dataProvider="ngModel"
                        class="create-select"
                        [ngClass]="{
                            failure: dataProvider.invalid && (dataProvider.dirty || dataProvider.touched)
                        }"
                        [(ngModel)]="point.dataProvider"
                        data-automation="dataProviderSelector"
                        appSelectValid
                        [class.loading-field]="loadingDataProviders"
                        required
                        [disabled]="(!point.isDynamic && isEditMode) || applyDataProviderToAllPoints"
                        (selectionChange)="handleDataProviderChange($event)"
                        placeholder="{{ 'measuredPoint.create.placeholder.data_provider' | translate }}"
                    >
                        <mat-option
                            *ngFor="let provider of dataProviders"
                            [value]="provider.id"
                            id="dataProvider_{{ provider.id }}"
                        >
                            {{ provider.displayLabel }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="dataProvider.invalid && (dataProvider.dirty || dataProvider.touched)">
                        <mat-error *ngIf="dataProvider.errors.required || dataProvider.errors.selectValid">
                            <span data-automation="alertRequired">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingDataProviders" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="loadingDataProviders" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'measuredPoint.create.data_provider' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="dataProvider">
                        {{ getSelectedDataProviderLabel() }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="false" class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.meter_type' | translate }}</mat-label>
                    <mat-select
                        id="meterType_{{ index }}"
                        name="meterType_{{ index }}"
                        #meterType="ngModel"
                        class="create-select"
                        [ngClass]="{ failure: meterType.invalid && (meterType.dirty || meterType.touched) }"
                        [(ngModel)]="point.formulaId"
                        data-automation="meterTypeSelector"
                        (selectionChange)="onMeterTypeChange($event)"
                        [class.loading-field]="loadingEquipmentModels"
                        appSelectValid
                        placeholder="{{ 'measuredPoint.create.meter_type' | translate }}"
                        optional
                    >
                        <mat-option
                            *ngFor="let equipmentModel of equipmentModels"
                            [value]="equipmentModel.formulaId"
                            id="meterType_{{ equipmentModel.id }}"
                        >
                            {{ equipmentModel.displayLabel }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="meterType.invalid && (meterType.dirty || meterType.touched)">
                        <mat-error *ngIf="meterType.errors.required || meterType.errors.selectValid">
                            <span data-automation="alertRequired">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingEquipmentModels" align="end">
                        {{ 'equipment.loading' | translate }}
                    </mat-hint>
                    <mat-spinner *ngIf="loadingEquipmentModels" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'measuredPoint.create.meter_type' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="meterType">
                        {{ pointChannel }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.source_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="sourceId_{{ index }}"
                        #sourceId="ngModel"
                        type="text"
                        [ngClass]="{ failure: sourceId.invalid && (sourceId.dirty || sourceId.touched) }"
                        name="sourceId_{{ index }}"
                        placeholder="{{ 'measuredPoint.create.placeholder.source_id' | translate }}"
                        minlength="6"
                        maxlength="80"
                        pattern="^[a-zA-Z0-9\-_\\s]+$"
                        [(ngModel)]="point.sourceId"
                        data-automation="sourceId"
                        data-url-persist
                        required
                        appSourceValid
                    />
                    <mat-error *ngIf="sourceId.invalid && (sourceId.dirty || sourceId.touched)">
                        <mat-error *ngIf="sourceId.errors.required">
                            <span data-automation="alertRequired">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.pattern">
                            <span data-automation="patternError">{{ 'equipment.validation.pattern' | translate }}</span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.sourceInvalid">
                            <span data-automation="sourceIDInvalid">
                                {{ 'equipment.validation.sourceInvalid' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.minlength">
                            <span data-automation="lengthError">
                                {{ 'equipment.validation.minLength' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'measuredPoint.create.source_id' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="sourceId">
                        {{ pointSourceId }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.pulse_multiplier' | translate }}</mat-label>
                    <input
                        matInput
                        id="multiplier_{{ index }}"
                        #multiplier="ngModel"
                        type="number"
                        step=".001"
                        [ngClass]="{
                            failure: multiplier.invalid && (multiplier.dirty || multiplier.touched)
                        }"
                        name="multiplier_{{ index }}"
                        placeholder="{{ 'measuredPoint.create.placeholder.pulse_multiplier' | translate }}"
                        maxlength="80"
                        [(ngModel)]="point.multiplier"
                        data-automation="multiplier"
                        data-url-persist
                        required
                        pattern="^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$"
                        min="0.001"
                    />
                    <mat-error *ngIf="multiplier.invalid">
                        <mat-error *ngIf="multiplier.errors.required">
                            <span data-automation="alertRequired">
                                {{ 'equipment.validation.required' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="multiplier.errors.pattern">
                            <span data-automation="validationError">
                                {{ 'equipment.validation.greater_than_zero' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'measuredPoint.create.pulse_multiplier' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="multiplier">
                        {{ point.multiplier }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isCreateMode || isEditMode" class="form-entry form-entry-column">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'measuredPoint.create.follows' | translate }}</mat-label>
                    <mat-select
                        id="follows_{{ index }}"
                        name="follows_{{ index }}"
                        [ngClass]="{
                            'create-select': true,
                            'input-entry': true
                        }"
                        [class.loading-field]="appData.loadingSiteMachines"
                        [(ngModel)]="point.follows"
                        data-automation="follows"
                        [disabled]="!siteMachines"
                        multiple
                        placeholder="{{ 'measuredPoint.create.placeholder.follows' | translate }}"
                    >
                        <mat-optgroup *ngFor="let group of siteMachines | keyvalue" [label]="group.key">
                            <mat-option
                                *ngFor="let machine of group.value"
                                [value]="machine.id"
                                id="machines_{{ machine.id }}"
                            >
                                {{ machine.displayLabel }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                    <mat-hint *ngIf="loadingSiteMachines" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="loadingSiteMachines" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <mat-list *ngIf="point.follows">
                    <mat-list-item
                        class="list-item"
                        *ngFor="let selectedMachineId of point.follows; let machineIndex = index"
                    >
                        <ng-container *ngIf="getEquipment(selectedMachineId)">
                            <i
                                (click)="removeSelectedMachine(selectedMachineId)"
                                id="removeSelectedMachine_{{ index }}_{{ machineIndex }}"
                            >
                                <img
                                    class="edit-icon icon-list"
                                    src="../../../assets/favicons/enelx/minus-button.svg"
                                    alt=""
                                />
                            </i>
                            <span class="label">
                                {{ getEquipment(selectedMachineId).displayLabel }}
                            </span>
                        </ng-container>
                    </mat-list-item>
                </mat-list>
                <ng-container *ngIf="point.follows.length === 0">
                    {{ 'measuredPoint.create.placeholder.no_follows' | translate }}
                </ng-container>
            </div>

            <div *ngIf="isCreateMode || isEditMode" class="form-entry form-entry-column">
                <div>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'measuredPoint.create.expression_factor' | translate }}</mat-label>
                        <input
                            matInput
                            id="expressionFactor_{{ index }}"
                            #expressionFactor="ngModel"
                            class="input-entry"
                            [ngClass]="{
                                failure: expressionFactor.invalid
                            }"
                            name="expressionFactor_{{ index }}"
                            placeholder="{{ 'measuredPoint.create.placeholder.expression_factor' | translate }}"
                            [(ngModel)]="point.expressionFactor"
                            data-automation="expressionFactor"
                            data-url-persist
                            required
                            type="number"
                            pattern="^-?(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$"
                            maxlength="80"
                            step=".01"
                        />
                        <mat-error *ngIf="expressionFactor.invalid">
                            <mat-error *ngIf="expressionFactor.errors.required">
                                <span data-automation="alertRequired">
                                    {{ 'equipment.validation.required' | translate }}
                                </span>
                            </mat-error>
                            <mat-error *ngIf="expressionFactor.errors.pattern">
                                <span data-automation="validationError">
                                    {{'equipment.validation.not_rule' | translate }} 0
                                </span>
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="checkbox-wrapper">
                    <mat-radio-group [(ngModel)]="point.isCumulative" name="aggregateGroup_{{ index }}">
                        <div class="radio-title">{{ 'measuredPoint.create.point_aggregation' | translate }}:</div>
                        <mat-radio-button
                            id="cumulative_{{ index }}"
                            name="pointAggregation_{{ index }}"
                            class="create-radio radio-mobile"
                            [value]="true"
                            title="{{ 'measuredPoint.create.cumulative' | translate }}"
                            >{{ 'measuredPoint.create.cumulative' | translate }}</mat-radio-button
                        >
                        <mat-radio-button
                            id="absolute_{{ index }}"
                            name="pointAggregation_{{ index }}"
                            class="create-radio"
                            [value]="false"
                            title="{{ 'measuredPoint.create.absolute' | translate }}"
                            >{{ 'measuredPoint.create.absolute' | translate }}</mat-radio-button
                        >
                    </mat-radio-group>
                </div>
                <div class="checkbox-wrapper pad-3-t">
                    <div>
                        <mat-checkbox
                            id="isActive_{{ index }}"
                            #isActive="ngModel"
                            [ngClass]="{ failure: isActive.invalid && (isActive.dirty || isActive.touched) }"
                            name="isActive_{{ index }}"
                            [(ngModel)]="point.isActive"
                            data-automation="isActiveInput"
                            data-url-persist
                        >
                            {{ 'measuredPoint.create.is_active' | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="isActive.invalid && (isActive.dirty || isActive.touched)">
                            <mat-error *ngIf="isActive.errors.required">
                                <span data-automation="alertRequired">
                                    {{ 'equipment.validation.required' | translate }}
                                </span>
                            </mat-error>
                        </mat-error>
                    </div>
                </div>

                <ng-container *ngIf="hideVee === false && !veeBypassFromPoint()">
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="veeGapCheck_{{ index }}"
                            #veeGapCheck="ngModel"
                            [ngClass]="{ failure: veeGapCheck.invalid && (veeGapCheck.dirty || veeGapCheck.touched) }"
                            name="veeGapCheck_{{ index }}"
                            [(ngModel)]="point.veeGapCheck"
                            data-automation="veeGapCheck"
                            data-url-persist
                        >
                            {{ 'measuredPoint.create.set_vee_gap_check' | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="veeGapCheck.invalid">
                            <mat-error *ngIf="veeGapCheck.errors.required">
                                <span data-automation="alertRequired">
                                    {{ 'equipment.validation.required' | translate }}
                                </span>
                            </mat-error>
                        </mat-error>
                    </div>
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="veeHighReadCheck_{{ index }}"
                            #veeHighReadCheck="ngModel"
                            [ngClass]="{
                                failure:
                                    veeHighReadCheck.invalid && (veeHighReadCheck.dirty || veeHighReadCheck.touched)
                            }"
                            name="veeHighReadCheck_{{ index }}"
                            [(ngModel)]="point.veeHighReadCheck"
                            data-automation="veeHighReadCheck"
                            data-url-persist
                            [disabled]="true"
                        >
                            {{ 'measuredPoint.create.vee_high_read_check' | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="veeHighReadCheck.invalid">
                            <mat-error *ngIf="veeHighReadCheck.errors.required">
                                <span data-automation="alertRequired">
                                    {{ 'equipment.validation.required' | translate }}
                                </span>
                            </mat-error>
                        </mat-error>
                    </div>
                    <div class="checkbox-wrapper pad-1-t">
                        <mat-form-field appearance="outline" floatLabel="always" class="small-number-size">
                            <mat-label>{{ 'measuredPoint.create.vee_high_read_threshold' | translate }}</mat-label>
                            <input
                                matInput
                                id="veeHighReadThreshold_{{ index }}"
                                #veeHighReadThreshold="ngModel"
                                type="number"
                                step="1"
                                [ngClass]="{
                                    failure:
                                        veeHighReadThreshold.invalid &&
                                        (veeHighReadThreshold.dirty || veeHighReadThreshold.touched)
                                }"
                                name="veeHighReadThreshold_{{ index }}"
                                placeholder="{{
                                    'measuredPoint.create.placeholder.vee_high_read_threshold' | translate
                                }}"
                                [(ngModel)]="point.veeHighReadThreshold"
                                data-automation="veeHighReadThreshold"
                                data-url-persist
                                required
                                [disabled]="!point.veeHighReadCheck"
                                min="0"
                                maxlength="12"
                            />
                            <mat-error *ngIf="veeHighReadThreshold.invalid">
                                <mat-error *ngIf="veeHighReadThreshold.errors.required">
                                    <span data-automation="alertRequired">
                                        {{ 'equipment.validation.required' | translate }}
                                    </span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="veeNegativeReadCheck_{{ index }}"
                            #veeNegativeReadCheck="ngModel"
                            [ngClass]="{
                                failure:
                                    veeNegativeReadCheck.invalid &&
                                    (veeNegativeReadCheck.dirty || veeNegativeReadCheck.touched)
                            }"
                            name="veeNegativeReadCheck_{{ index }}"
                            [(ngModel)]="point.veeNegativeReadCheck"
                            data-automation="veeNegativeReadCheck"
                            data-url-persist
                        >
                            {{ 'measuredPoint.create.vee_negative_read_check' | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="veeNegativeReadCheck.invalid">
                            <mat-error *ngIf="veeNegativeReadCheck.errors.required">
                                <span data-automation="alertRequired">
                                    {{ 'equipment.validation.required' | translate }}
                                </span>
                            </mat-error>
                        </mat-error>
                    </div>
                    <div class="checkbox-wrapper">
                        <mat-checkbox
                            id="veeZeroCheck_{{ index }}"
                            #veeZeroCheck="ngModel"
                            [ngClass]="{
                                failure: veeZeroCheck.invalid && (veeZeroCheck.dirty || veeZeroCheck.touched)
                            }"
                            name="veeZeroCheck_{{ index }}"
                            [(ngModel)]="point.veeZeroCheck"
                            data-automation="veeZeroCheck"
                            data-url-persist
                        >
                            {{ 'measuredPoint.create.vee_zero_check' | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="veeZeroCheck.invalid">
                            <mat-error *ngIf="veeZeroCheck.errors.required">
                                <span data-automation="alertRequired">
                                    {{ 'equipment.validation.required' | translate }}
                                </span>
                            </mat-error>
                        </mat-error>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="isViewMode">
                <div class="form-entry">
                    <mat-label class="info-title">
                        {{ 'measuredPoint.create.expression_factor' | translate }}:
                    </mat-label>
                    <mat-label class="info-value" data-automation="expressionFactor">
                        {{ point.expressionFactor }}
                    </mat-label>
                </div>
            </ng-container>

            <ng-container *ngIf="isViewMode">
                <div class="form-entry">
                    <mat-label class="info-title">
                        {{ 'measuredPoint.create.point_aggregation' | translate }}:
                    </mat-label>
                    <mat-label class="info-value" data-automation="pointAggregation">
                        {{
                            point.isCumulative
                                ? ('measuredPoint.create.cumulative' | translate)
                                : ('measuredPoint.create.absolute' | translate)
                        }}
                    </mat-label>
                </div>
            </ng-container>

            <div *ngIf="isViewMode" class="form-entry">
                <ng-container>
                    <mat-label class="info-title">{{ 'measuredPoint.create.is_active' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="isActive">
                        {{ point.isActive ? ('APP.TRUE' | translate) : ('APP.FALSE' | translate) }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isViewMode && !hideVee" class="form-entry">
                <ng-container>
                    <mat-label class="info-title">
                        {{ 'measuredPoint.create.set_vee_gap_check' | translate }}:
                    </mat-label>
                    <mat-label class="info-value" data-automation="veeGapCheck">
                        {{ point.veeGapCheck ? ('APP.TRUE' | translate) : ('APP.FALSE' | translate) }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isViewMode && !hideVee" class="form-entry">
                <ng-container>
                    <mat-label class="info-title">
                        {{ 'measuredPoint.create.vee_high_read_check' | translate }}:
                    </mat-label>
                    <mat-label class="info-value" data-automation="veeHighReadCheck">
                        {{ point.veeHighReadCheck ? ('APP.TRUE' | translate) : ('APP.FALSE' | translate) }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isViewMode && !hideVee" class="form-entry">
                <ng-container>
                    <mat-label class="info-title">
                        {{ 'measuredPoint.view.vee_high_read_threshold' | translate }}:
                    </mat-label>
                    <mat-label class="info-value" data-automation="veeHighReadThreshold">
                        {{ point.veeHighReadThreshold }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isViewMode && !hideVee" class="form-entry">
                <ng-container>
                    <span class="info-title">{{ 'measuredPoint.create.vee_negative_read_check' | translate }}:</span>
                    <mat-label class="info-value" data-automation="veeNegativeReadCheck">
                        {{ point.veeNegativeReadCheck ? ('APP.TRUE' | translate) : ('APP.FALSE' | translate) }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isViewMode && !hideVee" class="form-entry">
                <ng-container>
                    <span class="info-title">{{ 'measuredPoint.create.vee_zero_check' | translate }}:</span>
                    <mat-label class="info-value" data-automation="veeZeroCheck">
                        {{ point.veeZeroCheck ? ('APP.TRUE' | translate) : ('APP.FALSE' | translate) }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isViewMode" class="form-entry-col">
                <h4 class="group-header">{{ 'measuredPoint.create.follows' | translate }}:</h4>
                <div *ngIf="followsMachines()" class="group-wrapper">
                    <ng-container *ngFor="let group of this.siteMachines | keyvalue">
                        <div class="group-col">
                            <div class="group-label">{{ group.key }}</div>
                            <ng-container *ngFor="let equipmentId of this.point.follows">
                                <mat-label class="info-entry" *ngIf="isEquipmentInGroup(equipmentId, group.value)">
                                    {{ getEquipment(equipmentId) ? getEquipment(equipmentId).displayLabel : '' }}
                                </mat-label>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="!followsMachines()" class="pad-1-t">
                    <mat-label class="info-placeHolder">
                        {{ 'measuredPoint.view.follows.empty' | translate }}
                    </mat-label>
                </div>
            </div>
            <div>
                <hr class="form-divider sub-divider" />
                <app-formulas [mode]="mode" [appData]="appData" [point]="point" [pointIndex]="index"></app-formulas>
            </div>
        </div>
    </mat-expansion-panel>
</div>
</ng-container>

