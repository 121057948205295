<div
    id="controlSetForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
    ngModelGroup="controlSetForm"
>
    <div class="form-wrapper">
        <ng-container *ngIf="isCreateMode || isEditMode">
            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.supported_locales' | translate }}</mat-label>
                    <mat-select
                        id="supportedLocale"
                        name="supportedLocale"
                        #supportedLocale="ngModel"
                        [ngClass]="{
                            failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                        [(ngModel)]="multiLocaleConfig.supportedLocales"
                        (selectionChange)="handleSelectionChange()"
                        data-automation="supportedLocale"
                        required
                        placeholder="{{ 'sharedFields.create.placeholder.supported_locales' | translate }}"
                        multiple
                    >
                        <mat-option
                            [disabled]="limitLocaleSelectList(5, locale)"
                            *ngFor="let locale of multiLocaleConfig.locales"
                            [value]="locale"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="supportedLocale.invalid">
                        <mat-error *ngIf="supportedLocale.errors.pattern">
                            {{ 'equipment.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <ng-container>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'sharedFields.create.default_locale' | translate }}</mat-label>
                        <mat-select
                            id="locale"
                            name="locale"
                            #locale="ngModel"
                            [ngClass]="{
                                failure: locale.invalid && (locale.dirty || locale.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="controlSet.defaultLocale"
                            [disabled]="multiLocaleConfig.supportedLocales.length === 0"
                            data-automation="locale"
                            required
                            placeholder="{{ 'sharedFields.create.placeholder.default_locale' | translate }}"
                        >
                            <mat-option
                                *ngFor="let locale of multiLocaleConfig.supportedLocales"
                                [value]="locale.localeName"
                                >{{ locale.displayLabel }}</mat-option
                            >
                        </mat-select>
                        <mat-error *ngIf="locale.invalid">
                            <mat-error *ngIf="locale.errors.pattern">
                                {{ 'equipment.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="form-entry">
                <ng-container>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'sharedFields.create.timezone' | translate }}</mat-label>
                        <mat-select
                            id="timezone"
                            name="timezone"
                            #timezone="ngModel"
                            [ngClass]="{
                                failure: timezone.invalid && (timezone.dirty || timezone.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="controlSet.timezone"
                            data-automation="timezone"
                            required
                            placeholder="{{ 'sharedFields.create.placeholder.timezone_selector' | translate }}"
                        >
                            <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                                timezone.displayLabel
                            }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="timezone.invalid">
                            <mat-error *ngIf="timezone.errors.pattern">
                                {{ 'equipment.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [(ngModel)]="controlSet.displayLabels"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [id]="'control_set_display_labels'"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.site' | translate }}</mat-label>
                    <mat-select
                        id="siteSelector"
                        name="site"
                        #site="ngModel"
                        class="input-entry create-select"
                        [class.loading-field]="_data.loadingSites"
                        [ngClass]="{ failure: site.invalid && (site.dirty || site.touched) }"
                        [(ngModel)]="controlSet.siteId"
                        [disabled]="isEditMode"
                        (selectionChange)="onSiteChange($event)"
                        data-automation="siteSelector"
                        appSelectValid
                        required
                        placeholder="{{
                            (appData.sites.length > 0
                                ? 'sharedFields.create.placeholder.site_selector'
                                : 'sharedFields.create.placeholder.no_sites'
                            ) | translate
                        }}"
                        matTooltip="{{siteIdToDisplay}}" matTooltipPosition="above"
                    >
                        <mat-option *ngFor="let site of appData.sites" [value]="site.id" id="site_{{ site.id }}"
                        matTooltip="{{site.displayLabel}}" matTooltipPosition="above">{{
                            site.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="site.invalid">
                        <mat-error *ngIf="site.errors.selectValid">
                            <span>{{ 'equipment.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="_data.loadingSites" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="_data.loadingSites" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'controlSet.create.address' | translate }}</mat-label>
                    <input
                        matInput
                        id="address"
                        #address="ngModel"
                        type="text"
                        [ngClass]="{
                            failure: address.invalid && (address.dirty || address.touched),
                            'input-entry': true
                        }"
                        name="address"
                        placeholder="{{ 'controlSet.create.placeholder.address' | translate }}"
                        maxlength="80"
                        [(ngModel)]="controlSet.address"
                        (change)="resetProvider($event)"
                        data-automation="address"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="address.invalid">
                        <mat-error *ngIf="address.errors.required">
                            <span>{{ 'equipment.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'controlSet.create.provider' | translate }}</mat-label>
                    <mat-select
                            id="provider"
                            name="provider"
                            #deviceType="ngModel"
                            data-automation="provider"
                            [(ngModel)]="selectedProvider"
                            (selectionChange)="updateProvider($event)"
                            required
                            placeholder="{{ 'controlSet.create.placeholder.provider' | translate }}"
                    >
                        <mat-option *ngFor="let provider of providers" [value]="provider.value">
                            {{provider.displayLabel}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'controlSet.create.deviceType' | translate }}</mat-label>
                    <mat-select
                        id="deviceType"
                        name="deviceType"
                        #deviceType="ngModel"
                        data-automation="deviceType"
                        [ngClass]="{
                            failure: deviceType.invalid && (deviceType.dirty || deviceType.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                        [(ngModel)]="controlSet.deviceType"
                        (selectionChange)="updateAvailableCommands($event)"
                        required
                        placeholder="{{ 'controlSet.create.placeholder.deviceType' | translate }}"
                    >
                        <mat-option
                            *ngFor="let device of appData.devices"
                            [value]="device.deviceType"
                            id="deviceType_{{ device.deviceType }}"
                            >{{ device.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="deviceType.invalid && (deviceType.dirty || deviceType.touched)">
                        <mat-error *ngIf="deviceType.errors.required">
                            {{ 'equipment.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <multi-locale-input
                    name="descriptions"
                    [inputLabel]="descriptionLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [(ngModel)]="controlSet.descriptions"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [required]="false"
                    [id]="'control_set_descriptions'"
                >
                </multi-locale-input>
            </div>
            <div class="form-entry" *ngIf="isCreateMode && syncAssets">
              <mat-checkbox
                name="applyToAllPoints"
                data-automation="applyToAllPoints"
                [(ngModel)]="addToFlexibleAsset"
                (change)="toggleAddToFlexibleAsset($event)"
              >
                {{ 'sharedFields.create.add_to_flexible_asset' | translate }}
                <mat-icon
                  matSuffix
                  svgIcon="enelx:general-info"
                  data-automation="applyToAllPointsTooltip"
                  class="tooltip-icon"
                  [matTooltipPosition]="'below'"
                  matTooltip="{{ 'sharedFields.create.tooltip.add_to_flexible_asset' | translate }}"
                ></mat-icon>
              </mat-checkbox>
            </div>
        </ng-container>

        <ng-container *ngIf="isViewMode">
            <div class="form-entry">
                <mat-label class="info-title">{{ 'equipment.create.equipment_type' | translate }}:</mat-label>
                <ng-container>
                    <mat-label class="info-entry" data-automation="type">
                        {{ getEquipmentTypeDisplayLabel() }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.site' | translate }}:</mat-label>
                <ng-container>
                    <mat-label class="reg-name-value info-entry" data-automation="site" matTooltip="{{ getSiteforControlSet() }}" matTooltipPosition="above">
                        {{ getSiteforControlSet() }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'controlSet.create.address' | translate }}:</mat-label>
                <mat-label *ngIf="addressExists" class="reg-name-value info-entry" data-automation="address">
                    {{ _controlSet.address }}
                </mat-label>
                <mat-label *ngIf="!addressExists" class="reg-name-value info-placeHolder" data-automation="address">
                    {{ 'controlSet.view.placeholder.address' | translate }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'controlSet.create.provider' | translate }}:</mat-label>
                <mat-label *ngIf="addressExists" class="reg-name-value info-entry">
                    {{ _controlSet.provider }}
                </mat-label>
                <mat-label *ngIf="!addressExists" class="reg-name-value info-placeHolder" data-automation="address">
                    {{ 'controlSet.view.placeholder.provider' | translate }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.locale' | translate }}:</mat-label>
                <mat-label class="locale-value info-entry">
                    {{ getLocaleForControlSet() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.timezone' | translate }}:</mat-label>
                <mat-label class="timezone-value info-entry">
                    {{ getTimezoneForControlSet() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'controlSet.create.deviceType' | translate }}:</mat-label>
                <mat-label class="info-entry">
                    {{ getDeviceTypeDisplayLabel() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.description' | translate }}:</mat-label>
                <mat-label *ngIf="descriptionExists" class="reg-name-value info-entry">
                    {{ _controlSet.description }}
                </mat-label>
                <mat-label *ngIf="!descriptionExists" class="reg-name-value info-placeHolder">
                    {{ 'sharedFields.view.placeholder.description' | translate }}
                </mat-label>
            </div>
        </ng-container>
    </div>

    <h3 *ngIf="controlSet" class="points-title" data-automation="viewTitle">
        {{ 'controlSet.create.controlElements' | translate }}
    </h3>

    <hr class="form-divider" />
    <ng-container *ngIf="hasControlElements">
        <ng-container *ngFor="let controlElement of controlSet.controlElements; let i = index; let c = count">
            <app-control-elements
                [mode]="mode"
                [control]="controlElement"
                [index]="i"
                [count]="c"
                [siteMeters]="siteMeters"
                [controlTypes]="controlTypes"
                [deviceCommands]="deviceCommands"
                [multiLocaleConfig]="multiLocaleConfig"
                [data]="_data"
                (addControlSetElement)="addControlSetElement()"
                (deleteControlSetElement)="deleteControlSetElement($event)"
                (editControlSetElement)="handleEdit()"
            >
            </app-control-elements>
        </ng-container>
    </ng-container>
</div>
