/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NavService } from './shared/services/global-nav.service';

/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { NgxGlobalContactFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';

import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global_alert/global-alert.module';
import { I18nService } from './shared/services/i18n.service';
import { EquipmentService } from './shared/services/equipment.service';
import { SupportingDataService } from './shared/services/supporting-data.service';
import { MultiLocaleModule } from 'multi-locale-input';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';
import { SelectValidator } from './shared/directives/select-valid.directive';
import { SourceValidator } from './shared/directives/source-id-valid.directive';

import { EquipmentGroupService } from './shared/services/equipment-group.service';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { EditComponent } from './edit/edit.component';
import { DeleteConfirmationComponent } from './delete/confirmation-dialog/delete-confirmation-dialog.component';
import { ListComponent } from './list/list.component';
import { MeterDetailsComponent } from './details/meter/meter-details.component';
import { ControlSetDetailsComponent } from './details/control-set/control-set-details.component';
import { MeasuredPointDetailsComponent } from './details/measured-point/measured-point-details.component';
import { ControlElementDetailsComponent } from './details/control-element/control-element-details.component';
import { MachineDetailsComponent } from './details/machine/machine-details.component';
import { BessDetailsComponent } from './details/bess/bess-details.component';
import { GeneratorDetailsComponent } from './details/generator/generator-details.component';
import { ViewComponent } from './view/view.component';
import { DefaultComponent } from './default/default.component';

import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { TimezonesService } from './shared/services/timezones.service';
import { SidenavService } from './shared/services/sidenav.service';
import { OrganizationsService } from './shared/services/organizations.service';
import { EquipmentDetailsService } from './shared/services/equipment-details.service';
import { EquipmentModelsService } from './shared/services/equipment-models.service';
import { FormValidatorService } from './shared/services/form-validator.service';

/* * * MATERIAL * * */
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from './shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormulasComponent } from './details/measured-point/formulas/formulas.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormulaRulesValidDirective } from './shared/directives/formula-rules-valid.directive';
import { GroupDetailsComponent } from './details/group/group-details.component';
import { EquipmentListComponent } from './details/equipment-list/equipment-list.component';
import { EquipmentGroupComponent } from './details/equipment-group/equipment-group.component';
import { TreeModule } from 'enel-tree';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { FlexibleAssetsService } from './shared/services/flexible-assets.service';
import { FlagService } from './shared/services/flag.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CreateComponent,
    DeleteConfirmationComponent,
    EditComponent,
    MeterDetailsComponent,
    ControlSetDetailsComponent,
    MeasuredPointDetailsComponent,
    ControlElementDetailsComponent,
    MachineDetailsComponent,
    BessDetailsComponent,
    GeneratorDetailsComponent,
    ViewComponent,
    DefaultComponent,
    SelectValidator,
    SourceValidator,
    FormulasComponent,
    FormulaRulesValidDirective,
    EquipmentGroupComponent,
    GroupDetailsComponent,
    EquipmentListComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    NgxGlobalContactFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TreeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([]),
    AppRoutingModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    MatIconModule,
    NgxDeeplinkerModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    SharedModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatListModule,
    MatIconModule,
    MultiLocaleModule,
    MatSidenavModule,
    MatTooltipModule,
    TreeViewModule,
    // ReactiveFormsModule,
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    GlobalAlertService,
    I18nService,
    EquipmentService,
    EquipmentDetailsService,
    EquipmentGroupService,
    EquipmentModelsService,
    SupportingDataService,
    FlexibleAssetsService,
    FormValidatorService,
    TimezonesService,
    LocalesService,
    SidenavService,
    OrganizationsService,
    DeeplinksService,
    NavService,
    FlagService,
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
