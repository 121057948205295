<div id="generator-form" ngModelGroup="generatorForm">
    <div class="form-wrapper">
        <ng-container *ngIf="isCreateMode || isEditMode">
            <div class="form-entry equipment-type" *ngIf="isEditMode">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'equipment.create.equipment_type' | translate }}</mat-label>
                    <mat-select
                        id="equipmentType"
                        name="equipmentType"
                        #equipmentType="ngModel"
                        [ngClass]="{
                            failure: equipmentType.invalid && (equipmentType.dirty || equipmentType.touched),
                            'create-select': true
                        }"
                        [(ngModel)]="this.generator.equipmentType"
                        data-automation="equipmentType"
                        data-url-persist
                        disabled
                        placeholder="{{ getEquipmentTypeDisplayLabel() }}"
                    >
                        <mat-option
                            *ngFor="let equipType of appData.equipmentTypesDropDown"
                            [value]="equipType.name"
                            id="equipType_{{ equipType.name }}"
                            >{{ equipType.displayLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.supported_locales' | translate }}</mat-label>
                    <mat-select
                        id="supportedLocale"
                        name="supportedLocale"
                        #supportedLocale="ngModel"
                        [ngClass]="{
                            failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched),
                            'create-select': true,
                            'input-entry': true
                        }"
                        [(ngModel)]="multiLocaleConfig.supportedLocales"
                        (selectionChange)="handleSelectionChange()"
                        data-automation="supportedLocale"
                        required
                        placeholder="{{ 'sharedFields.create.placeholder.supported_locales' | translate }}"
                        multiple
                    >
                        <mat-option
                            [disabled]="limitLocaleSelectList(5, locale)"
                            *ngFor="let locale of multiLocaleConfig.locales"
                            [value]="locale"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="supportedLocale.invalid">
                        <mat-error *ngIf="supportedLocale.errors.pattern">
                            {{ 'equipment.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <ng-container>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'sharedFields.create.default_locale' | translate }}</mat-label>
                        <mat-select
                            id="locale"
                            name="locale"
                            #locale="ngModel"
                            [ngClass]="{
                                failure: locale.invalid && (locale.dirty || locale.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="generator.defaultLocale"
                            [disabled]="multiLocaleConfig.supportedLocales.length === 0"
                            data-automation="locale"
                            required
                            placeholder="{{ 'sharedFields.create.placeholder.default_locale' | translate }}"
                        >
                            <mat-option
                                *ngFor="let locale of multiLocaleConfig.supportedLocales"
                                [value]="locale.localeName"
                                >{{ locale.displayLabel }}</mat-option
                            >
                        </mat-select>
                        <mat-error *ngIf="locale.invalid">
                            <mat-error *ngIf="locale.errors.pattern">
                                {{ 'equipment.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="form-entry">
                <ng-container>
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'sharedFields.create.timezone' | translate }}</mat-label>
                        <mat-select
                            id="timezone"
                            name="timezone"
                            #timezone="ngModel"
                            [ngClass]="{
                                failure: timezone.invalid && (timezone.dirty || timezone.touched),
                                'create-select': true,
                                'input-entry': true
                            }"
                            [(ngModel)]="generator.timezone"
                            data-automation="timezone"
                            required
                            placeholder="{{ 'sharedFields.create.placeholder.timezone_selector' | translate }}"
                        >
                            <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                                timezone.displayLabel
                            }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="timezone.invalid">
                            <mat-error *ngIf="timezone.errors.pattern">
                                {{ 'equipment.validation.required' | translate }}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </ng-container>
            </div>

            <div class="form-entry">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [(ngModel)]="generator.displayLabels"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [id]="'generator_display_labels'"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'sharedFields.create.site' | translate }}</mat-label>
                    <mat-select
                        id="siteSelector"
                        name="site"
                        #site="ngModel"
                        class="input-entry create-select"
                        [class.loading-field]="appData.loadingSites"
                        [ngClass]="{ failure: site.invalid && (site.dirty || site.touched) }"
                        [(ngModel)]="generator.siteId"
                        data-automation="site"
                        appSelectValid
                        required
                        (selectionChange)="onSiteChange($event)"
                        [disabled]="isEditMode"
                        placeholder="{{
                            (appData.sites.length > 0
                                ? 'sharedFields.create.placeholder.site_selector'
                                : 'sharedFields.create.placeholder.no_sites'
                            ) | translate
                        }}"
                        matTooltip="{{siteIdToDisplay}}" matTooltipPosition="above"
                    >
                        <mat-option *ngFor="let site of appData.sites" [value]="site.id" id="site_{{ site.id }}"
                        matTooltip="{{site.displayLabel}}" matTooltipPosition="above">{{
                            site.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="site.invalid">
                        <mat-error *ngIf="site.errors.selectValid">
                            <span>{{ 'equipment.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="appData.loadingSites" align="end">{{ 'equipment.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="appData.loadingSites" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <multi-locale-input
                    name="descriptions"
                    [inputLabel]="descriptionLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [(ngModel)]="generator.descriptions"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [required]="false"
                    [id]="'generator_descriptions'"
                >
                </multi-locale-input>
            </div>
            <div class="form-entry" *ngIf="isCreateMode && syncAssets">
              <mat-checkbox
                name="applyToAllPoints"
                data-automation="applyToAllPoints"
                [(ngModel)]="addToFlexibleAsset"
                (change)="toggleAddToFlexibleAsset($event)"
              >
                {{ 'sharedFields.create.add_to_flexible_asset' | translate }}
                <mat-icon
                  matSuffix
                  svgIcon="enelx:general-info"
                  data-automation="applyToAllPointsTooltip"
                  class="tooltip-icon"
                  [matTooltipPosition]="'below'"
                  matTooltip="{{ 'sharedFields.create.tooltip.add_to_flexible_asset' | translate }}"
                ></mat-icon>
              </mat-checkbox>
            </div>
        </ng-container>

        <!--- VIEW --->
        <ng-container *ngIf="isViewMode">
            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.name' | translate }}:</mat-label>
                <mat-label class="reg-name-value info-value" data-automation="generatorName">
                    {{ generator.displayLabel }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.locale' | translate }}:</mat-label>
                <mat-label class="reg-name-value info-value" data-automation="generatorLocale">
                    {{ getLocaleForGenerator() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.timezone' | translate }}:</mat-label>
                <mat-label class="reg-name-value info-value" data-automation="generatorTimezone">
                    {{ getTimezoneForGenerator() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.site' | translate }}:</mat-label>
                <mat-label class="reg-name-value info-value" data-automation="generatorSite" matTooltip="{{ getSiteForGenerator() }}" matTooltipPosition="above">
                    {{ getSiteForGenerator() }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-label class="info-title">{{ 'sharedFields.create.description' | translate }}:</mat-label>
                <mat-label *ngIf="generator.description" class="info-entry">
                    {{ generator.description }}
                </mat-label>
                <mat-label *ngIf="!generator.description" class="info-placeHolder">
                    {{ 'sharedFields.view.placeholder.description' | translate }}
                </mat-label>
            </div>
        </ng-container>
    </div>
</div>
