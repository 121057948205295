import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Meter } from '../../shared/model/equipment.model';
import { Timezone } from '../../shared/model/timezone.model';
import { Locale, LocaleBaseConfig } from '../../shared/model/locale.model';
import { Operator } from '../../shared/model/space.model';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import { TimezonesService } from 'src/app/shared/services/timezones.service';
import { LocalesService } from 'src/app/shared/services/locales.service';
import { EquipmentService } from 'src/app/shared/services/equipment.service';
import { SupportingDataService } from 'src/app/shared/services/supporting-data.service';
import { Point } from 'src/app/shared/model/equipment.model';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from '../../shared/services/organizations.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { FlagService } from '../../shared/services/flag.service';

@Component({
  selector: 'app-meter',
  templateUrl: './meter-details.component.html',
  styleUrls: ['./meter-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class MeterDetailsComponent implements OnInit {
  timezones: Timezone[];
  locales: Locale[];
  operators: Operator[];
  dataProviders: any[];
  pointTypes: any[];
  reportingIntervals: any[];
  site: string;
  org = '';
  selectedChannels: any[] = [];
  selectedDataProvider: string;
  loadingOperators = true;
  loadingSites = true;
  loadingDataProviders = true;
  applyDataProviderToAllPoints = true;
  addToFlexibleAsset = true;
  sourceIds: any[] = [];
  siteMachines = [];
  deviceName = '';
  meterName = '';
  hideVee = true;
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  namePlaceholder = '';
  nameLabel = '';
  descriptionPlaceholder = '';
  descriptionLabel = '';
  subscriptions: Subscription[] = [];
  siteIdToDisplay: string = '';

  _meter: Meter;
  _data: any;
  _mode: string;

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';

  constructor(
    private timezonesService: TimezonesService,
    private localesService: LocalesService,
    private orgSelectorService: ContextSelectorService,
    private equipmentService: EquipmentService,
    private supportingDataService: SupportingDataService,
    private router: Router,
    private translateService: TranslateService,
    private organizationsService: OrganizationsService,
    private form: NgForm,
    private flagService: FlagService
  ) {
    this.timezones = this.timezonesService.timezones;
    this.nameLabel = this.translateService.instant('sharedFields.create.name');
    this.namePlaceholder = this.translateService.instant('meter.create.placeholder.meter_name');
    this.descriptionLabel = this.translateService.instant('sharedFields.create.description');
    this.descriptionPlaceholder = this.descriptionLabel;
    this.equipmentService.addToFlexibleAsset$.next(this.addToFlexibleAsset);

    const contextSub = this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      this.org = org.id;
      if (org.id) {
        this.loadingDataProviders = true;
        await this.supportingDataService.setDataProviders(org.id);
        this.loadingDataProviders = false;
      }
    });

    const operatorsSub = this.supportingDataService.operators$.subscribe(operators => {
      this.loadingOperators = true;
      this.operators = operators;
      if (this.operators.length > 0) {
        this.loadingOperators = false;
      }
    });

    const pointTypesSub = this.equipmentService.pointTypes$.subscribe(pointTypes => {
      this.pointTypes = pointTypes;
    });

    const reportingIntervalsSub = this.supportingDataService.reportingIntervals$.subscribe(reportingIntervals => {
      reportingIntervals.sort((a: any, b: any) => {
        if (parseInt(a.id, 10) < parseInt(b.id, 10)) {
          return -1;
        } else if (parseInt(a.id, 10) > parseInt(b.id, 10)) {
          return 1;
        } else {
          return 0;
        }
      });

      this.reportingIntervals = reportingIntervals;
    });

    this.populateMeter({
      siteId: '-1',
      operator: '-1',
      defaultLocale: '',
      timezone: 'America/New_York',
      points: [new Point()],
      displayLabels: {},
      descriptions: {},
    });

    this.subscriptions.push(...[contextSub as unknown as Subscription, operatorsSub, pointTypesSub, reportingIntervalsSub]);
  }

  get syncAssets() {
    return this.flagService.getFlag('SYNC_ASSETS');
  }

  @Input()
  set appData(appData: any) {
    this._data = appData;
    this.meter.defaultLocale = appData.userLocale;
  }

  get appData() {
    return this._data;
  }

  @Input()
  set LocaleConfig(localeConfig: any) {
    this.multiLocaleConfig = localeConfig;
    this.buildSupportedLocales();
  }

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  @Input()
  set meter(meter: Meter) {
    if (meter) {
      this._meter = meter;
      this.updateMeterName();
      if (this._meter.site && this._data) {
        this._data.siteId = this._meter.site.id;
      }

      if (meter.points && meter.points.length > 0) {
        if (typeof meter.points[0].dataProvider === 'string') {
          this.selectedDataProvider = meter.points[0].dataProvider;
        } else {
          this.selectedDataProvider = (meter.points[0].dataProvider as any).id;
        }

        this.updateVeeSettings();
      }
      this.buildSupportedLocales();
    }

    this.updateSelectedChannels();
  }

  get meter() {
    return this._meter;
  }

  get points() {
    return this._meter.points;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get hasPoints() {
    return this._meter.points.length > 0;
  }

  get sitesLength() {
    if (this._data && this._data.sites) {
      return this._data.sites.length > 0;
    }
    return false;
  }

  getSiteForMeter() {
    if (this._meter && this._meter.site) {
      if (this._meter.site.displayLabels) {
        const displayLabel = this._meter.site.displayLabels[this.appData.userLocale || 'en_US']
          ? this._meter.site.displayLabels[this.appData.userLocale || 'en_US']
          : this._meter.site.displayLabels[this._meter.defaultLocale];

        return displayLabel;
      }
      return this._meter.site.displayLabel;
    }
  }

  getMeterOperator() {
    if (this._meter) {
      return this._meter.operator;
    }
  }

  getEquipmentTypeDisplayLabel() {
    if (this._data.equipmentTypes && this._meter.equipmentType) {
      return this._data.equipmentTypes.filter((e: any) => e.name === this._meter.equipmentType)[0].displayLabel;
    }
  }

  populateMeter(meterData) {
    this._meter = meterData;
  }

  getTimezoneForMeter() {
    if (this.timezones.length > 0 && this._meter && this._meter.timezone) {
      return (
        this.timezones.find(timezone => this._meter.timezone.indexOf(timezone.timezoneName) !== -1) || new Timezone()
      );
    }

    return new Timezone();
  }

  getLocaleForMeter() {
    if (
      this.multiLocaleConfig.locales &&
      this.multiLocaleConfig.locales.length > 0 &&
      this._meter &&
      this._meter.defaultLocale
    ) {
      return (
        this.multiLocaleConfig.locales.find(locale => this._meter.defaultLocale.indexOf(locale.localeName) !== -1) ||
        new Locale()
      );
    }
    return new Locale();
  }

  resetOperator(event) {
    if (event.target.value === '') {
      this.meter.operator = '-1';
    }
  }

  getMarketMeterId() {
    if (this._meter) {
      return this._meter.marketMeterId;
    }
  }

  getClassicNodeId() {
    if (this._meter) {
      return this._meter.classicNodeId;
    }
  }

  addDynamicPoint() {
    this._meter.points.push(new Point(true));
  }

  deleteDynamicPoint(index: number) {
    const newPoints: any[] = [];
    this._meter.points.forEach((point, pointIndex) => {
      if (index !== pointIndex) {
        newPoints.push(point);
      }
    });

    this._meter.points = [];
    setTimeout(() => {
      this._meter.points = newPoints;
      this.updateSelectedChannels();
      this.clearSourceIds();
      this.form.form.markAllAsTouched();
      this.form.form.markAsDirty();
    }, 50);
  }

  updateSelectedChannels() {
    this.selectedChannels = this._meter.points.map(point => `${point.deliveredChannelId}`);
  }

  updateSourceIds() {
    this.sourceIds = this._meter.points.map(point => point.sourceId);
  }

  clearSourceIds() {
    this.sourceIds = [];
  }

  onSiteChange({ value: siteId }) {
    this._data.siteId = siteId;
    const selectedSite = this._data.sites.find(site => siteId === site.id);
    if (selectedSite) {
      this.localesService.useSitesDefaultLocaleAndTimezone(selectedSite, this.meter, this.multiLocaleConfig);
      this.siteIdToDisplay = selectedSite.displayLabel;
    }
  }

  updateMeterName() {
    let meterNameInput = '';
    if (this.meter.displayLabels) {
      if (this._data.userLocale == this.meter.defaultLocale) {
        meterNameInput = this.meter.displayLabels[this._data.userLocale]
          ? this.meter.displayLabels[this._data.userLocale]
          : '';
        this.meterName = `${meterNameInput} ${this.deviceName}`;
      } else {
        this.meterName = this.meter.displayLabels[this.meter.defaultLocale];
      }
    }
  }

  dataProviderCompare(dataProvider1: any, dataProvider2: any) {
    return dataProvider1 && dataProvider2 ? dataProvider1 === dataProvider2.id : false;
  }

  handleDataProviderChange($event) {
    this.selectedDataProvider = $event.value;
    this.updateVeeSettings($event);
  }

  getSelectedDataProviderLabel() {
    if (this.selectedDataProvider && this.dataProviders && this.dataProviders.length > 0) {
      const provider = this.dataProviders.find(provider => provider.id === this.selectedDataProvider);
      if (provider) {
        return provider.displayLabel;
      }
      return '';
    }

    return '';
  }

  updateVeeSettings($event?) {
    const dataProviderId = $event ? $event.value : this.selectedDataProvider;
    if (this.dataProviders && this.dataProviders.length > 0) {
      const dataProvider = this.dataProviders.find(provider => {
        return provider.id === dataProviderId;
      });

      if (dataProvider) {
        this.hideVee = dataProvider.veeBypassDefault;
      }
    }
  }

  toggleDataProviderSelectionMethod(event: MatCheckboxChange) {
    this.supportingDataService.applyDataProviderToAllPoints$.next(event.checked);
  }

  toggleAddToFlexibleAsset(event: MatCheckboxChange) {
    this.equipmentService.addToFlexibleAsset$.next(event.checked);
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
      this.multiLocaleConfig.supportedLocales.length > num - 1 &&
      !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  handleLocaleInputChange() {
    this.updateMeterName();
  }

  async handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    }
    await this.supportingDataService.getLocaleSelectorChanged();
    if (this.isEditMode) {
      this.form.form.controls['localeInputsForm_meter_display_labels'].markAsDirty();
    }
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this._data.equipment && this._data.equipment.displayLabels) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this._data.equipment.displayLabels);
      for (let key of keys) {
        const localeFound = this.multiLocaleConfig.locales.find(locale => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  ngOnInit() {
    this.applyDataProviderToAllPoints = this.isCreateMode;
    this.supportingDataService.applyDataProviderToAllPoints$.next(this.isCreateMode);

    this.supportingDataService.dataProviders$.subscribe(dataProviders => {
      this.organizationsService.currentOrg$.subscribe();
      this.dataProviders = dataProviders;
      this.updateVeeSettings();
    });
    this.supportingDataService.fetchChannelWhitelists();
    this.supportingDataService.getChannels();
    this.equipmentService.setEquipmentTypesDropDown();
    this.equipmentService.setPointTypes();
    this.supportingDataService.setReportingIntervals();
  }
}
